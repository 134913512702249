// 考评档案管理列表页配置文件
export var SearchList = [
  {
    labe: '模板',
    code: 'templateCode',
    type: 'select',
    placeholder: '代码/名称',
    option: [
      { label: 'STANDARD_TEMPLATE01-标准考评模板-单一类', value: 'STANDARD_TEMPLATE01' },
      { label: 'STANDARD_TEMPLATE02-标准考评模板-非单一类', value: 'STANDARD_TEMPLATE02' },
      { label: 'STANDARD_TEMPLATE03-标准考评模板-类单一类', value: 'STANDARD_TEMPLATE03' }
    ]
  },
  {
    labe: '供应商编号',
    code: 'supperCode',
    type: 'input',
    placeholder: '请输入供应商编号'
  },
  {
    labe: '供应商名称',
    code: 'supperName',
    type: 'input',
    placeholder: '请输入供应商名称'
  }
]
export var SearchData = {
  templateCode: '',
  supperCode: '',
  supperName: ''
}
