<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <div class="evaluation-box">
      <div class="exportData">
        <el-button class="textBtn" @click="AddRework" type="text">+新增</el-button>
        <el-button class="textBtn" @click="ksAddRework" type="text">快速添加</el-button>
      </div>
      <div class="modelcheck">
        <el-form :model="form">
          <el-form-item label="选择模板" prop="modelCode" >
            <el-select size="small" filterable :loading="supplierLoading" @change="supChangeModel" v-model="form.modelCode">
              <el-option v-for="(items) in modelOptions"
                :key="items.value"
                :label="items.value+'-'+items.label"
                :value="items.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="tableList">
        <div class="table">
          <el-table :data="tableData" @selection-change="handleSelectionChange" stripe style="width: 100%">
            <!-- <el-table-column type="selection"  width="55"></el-table-column> -->
            <el-table-column prop="supperCode" label="供应商编码" width="100px" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="supperName" label="供应商名称" :show-overflow-tooltip="true" width=""></el-table-column>
            <el-table-column prop="templateCode" label="模板代码" width="200px" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="templateName" label="模板名称" :show-overflow-tooltip="true" width=""></el-table-column>
            <el-table-column prop="cpcode" label="参评品类定义" width="130px" :show-overflow-tooltip="true">
              <template #default="scope">
                <el-button size="mini" type="text" @click="handleModelShow(scope.$index, scope.row)">
                  <div class="gist">参评品类定义</div>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100px">
              <template #default="scope">
                <el-button type="text" size="mini" @click="eliminateDinlog(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="tablepage">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size = pageSize
            layout="total,  prev, pager, next, jumper"
            :total = total>
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <dialog-box ref="AddNewData" componentName="AddSupDialog" dialogWidth="650px" :dialogShow="AddcheckDialogShow" @handleClose='handleClose' title="新增"
    :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'addPrework', type:'primary', size:'mini'}]" @addPrework="peworkBtn"></dialog-box>

    <dialog-box ref="getSubModel" componentName="SubModel" dialogWidth="650px" :dialogShow="SubModelShow" :newSubModelData="newSubModelData" @handleClose='handleClose1' title="参评品类定义"
    :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'addSubModel', type:'primary', size:'mini'}]" @addSubModel="subModelBtn"></dialog-box>

    <dialog-box ref="dialogbox" dialogWidth="900px" :dialogShow="dialogShow" componentName="ChoiceSupplierBody" @handleClose='handleClose' @cancel="handleClose"
    title="参评供应商快速添加" :choiceData="choiceData" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'ksAddSub', type:'primary', size:'mini'}]" @ksAddSub="ksAddSubBtn">
    </dialog-box>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData } from './js/setting.js'
import { request } from '@/assets/js/http.js'
// import qs from 'qs'
// import dayjs from 'dayjs'
export default {
  components: Component.components,
  props: ['formData', 'dataFormat'],
  name: 'SupplierDefinition',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      form: {},
      newSubModelData: {},
      tableDataId: '',
      tableShow: 1,
      categoryData: {},
      categoryDataCopy: {},
      suplierData: {},
      suplierDataCopy: {},
      supplierAllData: [],
      choiceData: [],
      selectTabData: [],
      supplierLoading: false,
      dialogShow: false,
      ProdialogShow: false,
      AddcheckDialogShow: false,
      SubModelShow: false,
      warrCheckData: [],
      childData: { searchList: SearchList, searchData: SearchData },
      pageNum: 1, // 当前第几页
      pageSize: pageSize, // 每页显示多少条
      total: 0, // 共多少条
      tableData: [],
      modelOptions: [
        {
          label: '标准考评模板-单一类',
          value: 'STANDARD_TEMPLATE01'
        },
        {
          label: '标准考评模板-非单一类',
          value: 'STANDARD_TEMPLATE02'
        },
        {
          label: '标准考评模板-类单一类',
          value: 'STANDARD_TEMPLATE03'
        }
      ]
    }
  },
  created () {
    this.getDataList()
  },

  methods: {
    // 获取参评供应商定义列表
    getDataList (data) {
      const obj = {
        ...data
      }
      request('/api/evaluation/supplierDefinition/queryAll?pageNum=' + this.pageNum + '&' + 'pageSize=' + this.pageSize, 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.total = res.data.total
        }
      })
    },

    // 新增弹窗
    AddRework () {
      if (!this.form.modelCode) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择模板'
        })
      } else {
        this.AddcheckDialogShow = true
      }
    },
    // 点击新增里面的确认按钮
    peworkBtn () {
      var newsArr = this.$refs.AddNewData.$refs.content.form
      if (!newsArr.supplierCode) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择供应商'
        })
        return false
      }

      const obj = {
        supperCode: newsArr.supplierCode,
        supperName: newsArr.supplier,
        templateCode: this.form.modelCode,
        templateName: this.form.modelName
      }
      request('/api/evaluation/supplierDefinition/save', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '添加成功'
          })
          this.getDataList()
        }
        this.AddcheckDialogShow = false
      })
    },

    // 参评品类定义弹框
    handleModelShow (index, row) {
      this.newSubModelData = row
      this.tableDataId = row.id
      this.SubModelShow = true
    },
    // 点击参评品类定义弹框里的确认按钮
    subModelBtn () {
      var newSubModel = this.$refs.getSubModel.$refs.content.tableData
      if (newSubModel) {
        const obj = [
          ...newSubModel
        ]
        request('/api/evaluation/supplierDefinition/updateById?id=' + this.tableDataId, 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.$message({
              showClose: true,
              type: 'success',
              message: '操作成功'
            })
            this.getDataList()
          }
          this.SubModelShow = false
        })
      } else {
        this.$message({
          showClose: true,
          type: 'success',
          message: '请添加参评品类'
        })
      }
    },

    // 选择模板
    supChangeModel (val) {
      for (const item of this.modelOptions) {
        if (item.value === val) {
          this.form.modelName = item.label
        }
      }
    },

    // 快速添加弹框
    ksAddRework () {
      if (!this.form.modelCode) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择模板'
        })
      } else {
        request('/api/supplier/basicData/getListNoPage', 'get').then((Response) => {
          if (Response.code === '200') {
            this.choiceData = []
            var data = [...Response.data]
            this.supplierAllData = data
            for (var i = 0; i < data.length; i++) {
              data[i].label = data[i].name + ' ' + data[i].erpCode
              data[i].key = data[i].id
              this.choiceData.push(data[i])
            }
            this.dialogShow = true
          }
        })
      }
    },

    // 点击快速添加弹框里的确认按钮
    ksAddSubBtn () {
      var data = this.$refs.dialogbox.$refs.content.choicedData
      if (!data) {
        this.$message({
          showClose: true,
          type: 'warning',
          message: '请选择供应商'
        })
      } else {
        var choicedData = []
        const newArry = []
        this.dialogVisible = false
        for (let i = 0; i < this.supplierAllData.length; i++) {
          for (let j = data.length; j >= 0; j--) {
            if (this.supplierAllData[i].id === data[j]) {
              var tmpData = JSON.parse(JSON.stringify(this.supplierAllData[i]))
              tmpData.code = tmpData.id
              choicedData.push(tmpData.id)
              delete tmpData.id
              newArry.push(tmpData)
            }
          }
        }
        this.choiced = choicedData
        var newList = []
        newArry.forEach(item => {
          newList.push({
            supperCode: ~~item.erpCode,
            supperName: item.name,
            templateCode: this.form.modelCode,
            templateName: this.form.modelName
          })
        })
        newList.forEach(items => {
        })

        const obj = [
          ...newList
        ]
        request('/api/evaluation/supplierDefinition/saveOrUpdateAll', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.$message({
              showClose: true,
              type: 'success',
              message: '添加成功'
            })
            this.getDataList()
            this.dialogShow = false
          }
        })
      }
    },

    // 获取保修期定义选中的数据
    handleSelectionChange (val) {
      this.warrCheckData = val
    },

    // 删除
    eliminate (row) {
      request('/api/evaluation/supplierDefinition/delete?id=' + row.id, 'post').then((res) => {
        if (res.code === '200') {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功'
          })
          this.getDataList()
        }
      })
    },
    // 删除弹框
    eliminateDinlog (row) {
      this.$confirm('确认要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.eliminate(row)
      })
    },

    // 关闭弹窗
    handleClose () {
      this.AddcheckDialogShow = false
      this.SupdialogShow = false
      this.ProdialogShow = false
      this.dialogShow = false
    },
    handleClose1 () {
      this.SubModelShow = false
    },

    // 查询
    onSearch (data) {
      this.pageNum = 1
      this.getDataList(data)
    },
    // 清空
    clearSearch (data) {
      this.pageNum = 1
      this.getDataList(data)
    },
    // 分页
    handleSizeChange (val) {
      this.pageSize = val
      this.getDataList()
    },
    handleCurrentChange (val) {
      this.pageNum = val
      this.getDataList()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
.list{
  .exportData{
    border-bottom: 1px solid #dddcdc;
    padding: 8px 0px 5px;
  }
  .textBtn{
    font-size: 16px;
  }
  .tableList{
    margin-top: -10px;
    padding: 0;
  }

  .evaluation-box{
    padding: 0 30px;
  }
  .elrowflx{
    margin-bottom: 20px;
    :deep(.el-col){
      margin-bottom: 8px;
      line-height: 34px;
    }
  }
  .modelcheck{
    margin-top: 10px;
    :deep(.el-select--small){
      width: 430px;
    }
  }
}
</style>
